import type {Dictionary} from '@Libraries/content-planner-library';
import type {ReactElement} from 'react';
import type {FiltersHashmap, OnFiltersChangeHandler} from '@Components/checkbox-filters/checkbox-filters.types';
import type {BasePanel} from '@Components/panel/panel.types';
import type {EventVO} from "@Components/events/events.types";
import {SocialPost} from "@Components/social-media/post.vo";
import {EmailCampaign} from "@Components/email-marketing-campaigns/email-marketing-campaigns.types";

export const REGIONS_AND_RELIGIONS_CACHE_KEY = 'contentPlannerRegionsAndReligions';

export type EntriesMapForPlannerCell = {
  entriesMappedByDate: EntriesMappedByDate;
  areEntriesPopulated: boolean;
  lazyLoadingData?: ContentPlannerLazyLoadingData;
};

export type EntriesMappedByDate = Dictionary<ContentPlannerEntries>;
export type EntryTypeFilter = Record<EntryTypeFilterOption, boolean>;
export type SocialGlobalEventFilter = Record<SocialGlobalEventFilterOption, boolean>;
export type EntriesMap = Record<string, EntriesMapForPlannerCell>;
export type CustomEventPanelCallback = () => void;
export type AreaFilterIds = string[];

export enum EntryType {
  EMAIL = 'email',
  SOCIAL = 'social_post',
  EVENT = 'event',
  USER_EVENT = 'user_event'
}

export enum CalendarEntriesViewType {
  PLANNER = 'planner',
  EVENTS = 'events',
  EVENTS_SHARED_WITH_ME = 'events_shared_with_me'
}

export enum PlannerSeenCacheKeyPrefix {
  INTRO = 'planner_seen_',
  EVENTS = 'planner_events_seen_',
}

export interface PlannerSeenServerResponse {
  hasSeen: number;
}

export interface ContentPlannerState {
  date: Date;
  activeDate: Date;
  entriesMap: EntriesMap;
  regions: AreaFilterInfo[];
  religions: AreaFilterInfo[];
  contentPlannerFilters: ContentPlannerFilters;
  customEventFormData: BaseEvent;
  isNewEventBeingCreated: boolean;
  calendarEntriesViewType: CalendarEntriesViewType;
}

export interface ContentPlannerEntries {
  scheduledEntries: ScheduledEntry[];
  internalEventEntries: EventEntry[];
  userEventEntries: EventVO[];
  socialPostEntries: SocialPost[];
  emailCampaignEntries: EmailCampaign[];
  lazyLoadingData?: ContentPlannerLazyLoadingData;
}

export interface SocialAndEmailEntryPopulatedFlags {
  areSocialPostEntriesPopulated: boolean;
  areEmailEntriesPopulated: boolean;
}

export type FilteredContentPlannerEntries  = Omit<ContentPlannerEntries, 'lazyLoadingData'>;

export type ContentPlannerLazyLoadingData = {
  entryTypeLazyLoadData: ContentPlannerLazyLoadingMap;
  dateFrom: string;
  dateTo: string;
};

export interface ContentPlannerLazyLoadingMap {
  [entryType: string]: ContentPlannerLazyLoadDataForEntryType;
}

export interface ContentPlannerLazyLoadDataForEntryType {
  areMoreEntriesNeeded: boolean;
  latestPublishOn: number;
}

interface ContentPlannerEntryBase {
  timestamp: number;
}

enum ContentPlannerEntrySocialType {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  EMAIL = 'email',
}

export interface ScheduledEntrySocial {
  iconClasses: string;
  type: ContentPlannerEntrySocialType;
  text: string;
  textClasses: string;
  imageURL: string;
}

export interface ScheduledEntry extends ContentPlannerEntryBase {
  id: string;
  previewURL: string;
  entryType: EntryType;
  iconClasses: string;
  socials: ScheduledEntrySocial[];
  name: string;
}

export enum EventType {
  GLOBAL = 1,
  SOCIAL_MEDIA = 2,
  REGIONAL = 3,
  RELIGIOUS = 4,
  CUSTOM = 5,
}

export enum EntryTypeFilterOption {
  SOCIAL = 'social',
  EMAIL = 'email',
  CUSTOM_EVENT = 'custom_event',
}

export enum SocialGlobalEventFilterOption {
  SOCIAL_MEDIA = 'social_media',
  GLOBAL = 'global',
}

export interface BaseEvent extends ContentPlannerEntryBase {
  id: number;
  title: string;
  description: string;
}

export interface EventEntry extends BaseEvent {
  type: EventType;
  eventHashedId: string;
  regions: AreaFilterIds;
  religions: AreaFilterIds;
  isLunar?: boolean;
}

export interface CustomEventFormSetters {
  onTitleChange: (newTitle: string) => void;
  onDescriptionChange: (newDescription: string) => void;
  onDateChange: (newDate: Date) => void;
}

export interface ContentPlannerFilters {
  entryTypeFilters: EntryTypeFilter;
  socialGlobalEventFilters: SocialGlobalEventFilter;
  eventRegionFilters: FiltersHashmap;
  eventReligionFilters: FiltersHashmap;
}

export interface PlannerCellEntriesItems {
  eventItems: ReactElement[];
  allEventItems: ReactElement[];
  scheduledItems: ReactElement[];
  allScheduledItems: ReactElement[];
  isExpandedEntriesPopupNeeded: boolean;
}

export interface AreaFilterInfo {
  id: number;
  name: string;
}

export interface PlannerRegionsAndReligions {
  regions: AreaFilterInfo[];
  religions: AreaFilterInfo[];
}

export interface EventAreaFiltersFromStore {
  filters: FiltersHashmap;
  filtersStateSetter: OnFiltersChangeHandler;
  filtersSource: AreaFilterInfo[];
}

export interface ContentPlannerThunkParams {
  fromTimestamp: number;
  toTimestamp: number;
  lazyLoadingInfo?: ContentPlannerLazyLoadingMap;
  calendarEntriesViewType: CalendarEntriesViewType;
}

export interface CustomEventThunkParams {
  eventData: BaseEvent;
  entriesMapKey: string;
}

export interface EditCustomEventThunkParams extends CustomEventThunkParams {
  originalTimestamp: number;
}

export interface ContentPlannerCustomEventsPanelParams extends BasePanel {
  dialogMode?: boolean;
}

export interface CustomEventsPanelInnerContentProps {
  onClose: CustomEventPanelCallback;
  isInModal: boolean;
}

export enum PlannerEntryCellOperation {
  ADD = 'add',
  DELETE = 'delete',
}

export interface ModifyPlannerEntryCellParams {
  state: ContentPlannerState;
  date: Date;
  operation: PlannerEntryCellOperation;
  scheduledEntry?: ScheduledEntry;
  eventEntry?: EventEntry;
}

export interface EditScheduledEntryDateParams {
  newDate: Date;
  id: string;
}

export interface UserCalendarPreferencesServerResponse {
  userCalendarPreferences?: UserCalendarPreferencesFromServer;
}

export interface UserCalendarPreferencesBase {
  showGlobalEvents: boolean;
  showSocialEvents: boolean;
}

export interface UserCalendarPreferences extends UserCalendarPreferencesBase {
  showGlobalEvents: boolean;
  showSocialEvents: boolean;
  selectedRegions: AreaFilterIds;
  selectedReligions: AreaFilterIds;
}

interface UserCalendarPreferencesFromServer extends UserCalendarPreferencesBase {
  selectedRegions: number[];
  selectedReligions: number[];
}