import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {EventHashedID, EventsFilters, EventsReduxState, EventTimeFilterMap} from '@Components/events/events.types';
import {EventSortBy, EventsViewType, EventTimeFilter, UserEventCreationPermissionState} from '@Components/events/events.types';
import {getDefaultLazyData, getEventsLazyDataBasedOnAppliedFilters} from '@Libraries/events.library';
import {eventsExtraReducers, removeEventFromStore} from '@Components/events/events-extra-reducers';
import {LoadingStates} from '@Utils/loading.util';

const eventsInitialState: EventsReduxState = {
  allEvents: {},
  eventsListDefaultLazyData: getDefaultLazyData(),
  eventsListFilteredLazyData: getDefaultLazyData(),
  isListViewSelected: true,
  eventsViewType: EventsViewType.DEFAULT,
  filters: getDefaultEventsFilters(),
  userEventCreationPermissionState: UserEventCreationPermissionState.VALIDATING_PERMISSIONS,
  doShowEmbedBanner: true,
  pageMetaDataLoadingState: LoadingStates.NOT_LOADED,
};

const eventsSlice = createSlice({
  name: 'eventsSlice',
  initialState: eventsInitialState,
  reducers: {
    onEventsViewTypeChange: (state, action: PayloadAction<EventsViewType>): void => {
      state.eventsViewType = action.payload;
      resetStateToDefault(state);
    },

    setIsEventsListViewSelected: (state, action: PayloadAction<boolean>): void => {
      state.isListViewSelected = action.payload;
    },

    onEventTimeFilterChange: (state, action: PayloadAction<EventTimeFilterMap>): void => {
      state.filters.timeFilter = action.payload;
      resetFilteredLazyData(state);
    },

    onEventSearchTermChange: (state, action: PayloadAction<string>): void => {
      state.filters.searchTerm = action.payload;
    },

    onEventSortTypeChange: (state, action: PayloadAction<EventSortBy>): void => {
      state.filters.sortType = action.payload;
      resetFilteredLazyData(state);
    },

    triggerEventsLazyLoadOnScroll: (state): void  => {
      const lazyData = getEventsLazyDataBasedOnAppliedFilters(state, state.eventsViewType, state.filters);
      if (lazyData.loadMore) {
        lazyData.loadingState = LoadingStates.NOT_LOADED;
      }
    },

    removeEventFromEventsStore: (state, action: PayloadAction<EventHashedID>): void => {
      removeEventFromStore(state, action.payload);
    },
  },
  extraReducers: eventsExtraReducers
});

const resetStateToDefault = (state: EventsReduxState): void => {
  state.eventsListDefaultLazyData = getDefaultLazyData();
  resetFilteredLazyData(state);
  state.isListViewSelected = true;
  state.filters = {
    searchTerm: '',
    timeFilter: getDefaultTimeFilter(),
    sortType: EventSortBy.EVENT_START_DATE
  }
}

const resetFilteredLazyData = (state: EventsReduxState): void => {
  state.eventsListFilteredLazyData = getDefaultLazyData();
}

function getDefaultEventsFilters(): EventsFilters {
  return {
    timeFilter: getDefaultTimeFilter(),
    searchTerm: '',
    sortType: EventSortBy.EVENT_START_DATE,
  };
}
function getDefaultTimeFilter(): EventTimeFilterMap {
  return {
    [EventTimeFilter.LIVE]: true,
    [EventTimeFilter.UPCOMING]: true,
    [EventTimeFilter.PAST]: true
  }
}

export const {onEventsViewTypeChange, setIsEventsListViewSelected, onEventTimeFilterChange, onEventSortTypeChange, onEventSearchTermChange, triggerEventsLazyLoadOnScroll, removeEventFromEventsStore} = eventsSlice.actions;
export const eventsReducer = eventsSlice.reducer;
