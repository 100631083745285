import type {ContainerQueryObject} from '@Hooks/useContainerQuery';
import type {EventType} from '@Components/event-wizard/type-dropdown/type-dropdown.types';
import type {PlaceDetails} from '@Components/google-map/google-map.types';
import type {EventStyles} from '@Components/event-wizard/event-style/event-style.types';
import type {EventRegistrationFields} from '@Components/event-wizard/event-wizard-registrations/event-wizard-registrations.types';
import type {OrganiserDetails} from '@Components/event-wizard/event-wizard-organisers/event-wizard-organisers.types';
import type {RecurringEvent} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import type {LoadingStates} from '@Utils/loading.util';

export const NUM_SKELETON_ITEMS = 5;
export type EventHashedID = string;
export type AllEventsMap = Record<EventHashedID, EventVO>;
export type EventStatusFilterValues = EventStatus.DRAFT | EventStatus.LIVE | EventStatus.ERROR | EventStatus.PAST;
export type EventTimeFilterMap = Record<EventTimeFilter, boolean>;
export interface EventProps {
  eventsViewType: EventsViewType;
}

export interface EventVO {
  hashedID: EventHashedID;
  title: string;
  type: EventType;
  startDateTime: string;
  endDateTime: string;
  showCountdown: boolean;
  isRemoveWatermark: boolean;
  style: EventStyles;
  registrationFields: EventRegistrationFields;
  organiser?: OrganiserDetails;
  tzcode: string;
  recurring?: RecurringEvent;
  lastModified: string;
  description: string;
  status: EventStatus;
  img?: string;
  time?: EventTime;
  venue?: PlaceDetails;
  venueOnline?: EventOnlineVenue;
  isShared: boolean;
  isSharedOnGoogle?: boolean;
  isPast: boolean;
  startDateTimestamp: number;
  endDateTimestamp: number;
  numRegistrations: number;
  allowRegistrations: boolean;
  creatorId: number;
}

export interface EventOnlineVenue {
  link: string;
}

export interface EventTime {
  date_start: string;
  date_end: string;
  timezone: string;
}

export enum EventTimeFilter {
  LIVE = 1,
  UPCOMING = 2,
  PAST = 3,
}

export enum EventStatus {
  DRAFT = 1,
  LIVE = 2,
  ONGOING = 3,
  PAST = 4,
  ERROR = 5,
}

export enum ScreenSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

export enum EventsViewType {
  DEFAULT = 'default',
  SHARED_WITH_ME = 'shared_with_me',
}

export enum EventSortBy {
  LAST_MODIFIED = 1,
  EVENT_START_DATE = 2,
  ALPHABETICAL_ORDER_ASC = 3,
  ALPHABETICAL_ORDER_DESC = 4,
}

export enum UserEventCreationPermissionState {
  NEEDS_UPSELL = 'needs-upsell',
  VALIDATING_PERMISSIONS = 'validating-permissions',
  CAN_CREATE = 'can-create-events',
}

export const CONTAINER_QUERY: ContainerQueryObject = {
  small: {
    maxWidth: 456,
  },
  medium: {
    minWidth: 456,
    maxWidth: 680,
  },
  large: {
    minWidth: 680,
    maxWidth: 1020,
  },

  xlarge: {
    minWidth: 1020,
  },
};

export interface EventsFilters {
  timeFilter: EventTimeFilterMap;
  searchTerm: string;
  sortType: EventSortBy;
}

export interface EventsListLazyData {
  ids: EventHashedID[];
  loadingState: LoadingStates;
  loadMore: boolean;
}

export interface EventsReduxState {
  eventsViewType: EventsViewType;
  eventsListDefaultLazyData: EventsListLazyData;
  eventsListFilteredLazyData: EventsListLazyData;
  allEvents: AllEventsMap;
  isListViewSelected: boolean;
  filters: EventsFilters;
  userEventCreationPermissionState: UserEventCreationPermissionState;
  doShowEmbedBanner: boolean;
  pageMetaDataLoadingState: LoadingStates;
}

export interface FetchEventsFromServerParams {
  eventsViewType: EventsViewType;
  timeFilters: EventTimeFilterMap;
  searchTerm: string;
  sort: EventSortBy;
  offset: number;
}

export interface EventsPageMetaDataResponse {
  doShowUpsell: boolean;
  doShowEmbedBanner: boolean;
}
